import { Attribute } from "./attribute";
import { Collection } from "./collection";
import { FabricObject } from "./fabric";
import { Finish, FinishHandle } from "./finish";
import { PriceUmbrella } from "../pricing/price";
import { CustomUmbrella } from "../configurator/custom_umbrella";
import * as option from "../configurator/configurator_options";

export interface UmbrellaModel {
  _id: string;
  model: string;
  display_name: string;
  finishes: Finish[];
  dv?: boolean;
  size?: string;
  shape?: string;
  line_art_url?: string;
  product_lines?: Collection;
  price?: PriceUmbrella;
  image?: string;
  attributes: any;
  specs: {
    handle: string;
    display_name: string;
    value: string;
    unit: string;
  }[];
}

export interface FinishedUmbrella {
  // consists of select properties directly from the LineItem or LineItemRecord models found in the Node API and the Order Form  application.
  display_name: string;
  model: string;
  sku?: string;
  item_details?: CustomUmbrellaOrder; // type CustomUmbrellaOrder found in Order Form
}

export interface CustomUmbrellaOrder {
  canopy: CustomUmbrella["canopy"];
  frame:
    | CustomUmbrella["frame"]
    | {
        finish: FinishHandle;
        finial: option.Finial;
        finialFinish: FinishHandle | "chrome" | null;
        bottomPole: option.BottomPole | null;
        spigot: option.Spigot | null;
      };
}

export enum UmbrellaType {
  Market = "market",
  Giant = "giant",
  Cantilever = "cantilever",
  // Beach = "beach",
  // Patio = "patio"
}
